<template>
  <div class="feature-score">
    <div class="row">
      <div :class="hideLabel ? 'col-12' : 'col-7'">
        <label v-show="!hideLabel">Your Match Score: {{ matchScore }}</label>
        <score-bar :value="matchScore" />
      </div>
    </div>
  </div>
</template>

<script>
const ScoreBar = () => import("Components/ScoreBar");
export default {
  props: {
    vehicle: Object,
    featureName: String,
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    matchScore() {
      return Number(
        (this.vehicle[`${this.featureName}_Score`] * 100).toFixed(2)
      );
    },
  },
  components: {
    ScoreBar,
  },
};
</script>
<style lang="scss" scoped>
.feature-score {
  label {
    font-size: 0.8125rem;
    color: #162b32;
    font-weight: 700;
    margin-bottom: 2px;
  }
}
</style>
